import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"

const SectionPodcastsHostedByUpdate = () => {
  const data = useStaticQuery(graphql`
    query SectionPodcastsHostedByUpdateQuery {
      prismicPodcastsPage {
        _previewable
        data {
          top_title2
          main_title2
          section_content1
          button_name1
          button_link1 {
            link_type
            uid
            url
          }
          section_image_desktop {
            gatsbyImageData(
              width: 800
            )
          }
          section_image_mobile {
            gatsbyImageData(
              width: 800
            )
          }
        }
      }
    }
  `)

  const doc = data.prismicPodcastsPage
  const imgHost = getImage(doc.data.section_image_desktop)
  const imgHostMobile = getImage(doc.data.section_image_mobile)
  const buttonLink1Type = doc.data.button_link1.link_type
  const buttonLink1Uid = doc.data.button_link1.uid
  const buttonLink1Url = doc.data.button_link1.url

  return (    
    <div className="bg-white">
      <div className="relative w-full pt-8 pb-8 md:pt-16 md:pb-14">
        <div className='w-11/12 max-w-screen-xl flex flex-row items-center justify-center mx-auto space-x-4 lg:space-x-16 p-2'>
          <div className="w-2/3">
            <div className="z-20 flex flex-col items-start justify-start">
              <p className='text-gray-700 text-md md:text-3xl text-left uppercase mb-2 md:mb-4 tracking-widest'>{doc.data.top_title2}</p>
              <h2 className="text-site-red text-xl md:text-5xl text-left font-extrabold uppercase tracking-wider mb-2 md:mb-8">{doc.data.main_title2}</h2>
              <p className='text-gray-700 text-md md:text-2xl text-left mb-8'>{doc.data.section_content1}</p>

              <div className='hidden lg:block w-full lg:w-9/12 xl:w-8/12 z-10'>
                {buttonLink1Type === "Document" || buttonLink1Type === "Any"
                  ?
                    <Link to={buttonLink1Uid ? `/${buttonLink1Uid}` : "/"}>
                      <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white tracking-wider px-10 md:px-16 py-3 mb-8 xl:mb-0">{doc.data.button_name1}</button>
                    </Link>
                  :
                  buttonLink1Type === "Web" || buttonLink1Type === "Media"
                  ?
                    <a
                      href={buttonLink1Url ? buttonLink1Url : ""}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white tracking-wider px-10 md:px-16 py-3 mb-8 xl:mb-0">{doc.data.button_name1}</button>
                    </a>
                  :
                  null
                }
              </div>
            </div>
          </div>

          <div className="w-1/3 hidden lg:flex flex-col items-center justify-center">
            <GatsbyImage
              alt="Van Carlson photo"
              image={imgHost}
              formats={["auto", "webp", "avif"]}
              className="z-20"
            />
          </div>

          <div className="w-1/3 flex lg:hidden flex-col items-center justify-center z-10">
            <GatsbyImage
              alt="Van Carlson photo"
              image={imgHostMobile}
              formats={["auto", "webp", "avif"]}
              className="z-20"
            />
          </div>
        </div>

        <div className='block lg:hidden w-11/12 mx-auto z-10'>
          {buttonLink1Type === "Document" || buttonLink1Type === "Any"
            ?
              <Link to={buttonLink1Uid ? `/${buttonLink1Uid}` : "/"}>
                <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white tracking-wider px-10 md:px-16 py-3">{doc.data.button_name1}</button>
              </Link>
            :
            buttonLink1Type === "Web" || buttonLink1Type === "Media"
            ?
              <a
                href={buttonLink1Url ? buttonLink1Url : ""}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white tracking-wider px-10 md:px-16 py-3">{doc.data.button_name1}</button>
              </a>
            :
            null
          }
        </div>

        <div className="absolute bottom-20 lg:bottom-0 left-0 z-0">
          <StaticImage
            src="../../images/podcasts/backgrounds/hosted-by-bg-comp.png"
            width={490}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt=""
          />
        </div>
      </div> 
    </div>    
  )
}

export default SectionPodcastsHostedByUpdate